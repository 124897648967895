class Localization {

    private static localizationResources: any;

    private static getLocalizationResources() {
        if (!this.localizationResources) {
            const localizationResourcesText = $("#localization-textarea").text();
            this.localizationResources = JSON.parse(localizationResourcesText);
        }

        return this.localizationResources;
    }

    private static getTextFromDictionary(key: string, dictionary: any) {
        const text = dictionary[key];

        if (text === undefined) {
            throw Error("Undefined key \"" + key + "\" in dictionary");
        }

        return text;
    }

    public static getText(key: string) {
        return this.getTextFromDictionary(key, this.getLocalizationResources().text);
    }

    public static getTextAsLowerCase(key: string) {
        const text = this.getTextFromDictionary(key, this.getLocalizationResources().text);
        return text.toLowerCase();
    }

    private static formatString = (str: string, ...val: string[]) => {
        for (let index = 0; index < val.length; index++) {
            str = str.replace(`{${index}}`, val[index]);
        }
        return str;
    };

    public static formatLocalizationText = (key: string, ...val: string[]) => {
        const text = Localization.getText(key);
        return Localization.formatString(text, ...val);
    };

    public static formatLocalizationTextAsLowerCase = (key: string, ...val: string[]) => {
        const text = Localization.getTextAsLowerCase(key);
        return Localization.formatString(text, ...val);
    };
}

export {Localization};
