import {RequiredFieldMarker} from "./modules/requiredFieldmarker-module";
import {initEmbla, LeftMenuModule, TableModule, NumericInputModule} from "ditmer-embla";

import * as moment from "moment";
import "moment/locale/da";
import "jQueryExtensions";
import {AsyncErrorModule} from "./modules/asyncError-module";
import {Historiklog} from "./modules/historiklog-module";
import {INumericInputModuleOptions} from "ditmer-embla/dist/js/types/modules/numericInput/numericInput-module";
import * as sharedUtility from "./shared-utility";
import {initNotifications} from "./shared-utility";

declare global {
    interface Window {
        successMessage: string;
        infoMessage: string;
        errorMessage: string;
    }
}

// Disable ajax caching
$.ajaxSetup({
    cache: false,
    traditional: true,
    beforeSend: (jqXHR, settings) => {
        const requestMethod = settings.type;
        if(requestMethod !== "GET" && requestMethod !== "HEAD" && requestMethod !== "OPTIONS" && requestMethod !== "TRACE") { // Anti Forgery Token doesn't validate for these safe http methods, so don't add the token
            const globalAntiForgeryToken = $("input#AjaxAntiforgeryToken").val() as string;

            if(globalAntiForgeryToken) {
                jqXHR.setRequestHeader("RequestVerificationToken", globalAntiForgeryToken);
            }
        }
    }
});

$(document).ajaxError((event, jqXHR, settings, thrownError) => {
    console.log("ajax error", "xhr", jqXHR, "thrownError", thrownError, "responseText", jqXHR.responseText);

    if (thrownError === "abort") { // Do nothing if error is an aborted ajax request
        return;
    }

    AsyncErrorModule.CreateFromJQueryXHR(jqXHR)
        .then((asyncErrorModule) => {
            asyncErrorModule.handleError();
        });
});


// Default select2 language to danish
($.fn as any).select2.defaults.set("language", "da");

(function() {

    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);

    // We listen to the resize event
        window.addEventListener('resize', () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });
    // ---------------------------------
    $(document).ready(function() {
        initEmbla();

        $(".navbar-nav .dropdown-menu > .sub-dropdown > .sub-dropdown-toggle").on("click", (e) => {
            e.stopPropagation();

            const $subDropdownToggle = $(e.currentTarget);

            const collapseSelector = $subDropdownToggle.attr("href");

            $(collapseSelector).collapse("toggle");
        });

        $("#language-picker label").on("click",
            function (e) {
                e.preventDefault();
                $(this).closest("a")[0].click();
            });

        $("body").removeClass("preload"); // ".preload" class is fix for IE transition jump on page loads - it should be removed on $(document).ready(). See https://css-tricks.com/transitions-only-after-page-load/

        if ($(".left-menu-header-toggle").length > 0) {
            new LeftMenuModule({
                toggleMenuSelector: ".left-menu-header-toggle",
                persistState: true,
                persistStateForDays: 1,
                leftMenuCallbacks: {
                    menuBeginToggleCallback: () => {
                        TableModule.hideOverflow();
                    },
                    menuDidToggleCallback: () => {
                        TableModule.resize();
                        TableModule.showOverflow();
                    }
                }
            });
        }

        sharedUtility.reinitializeDatepickers();
        sharedUtility.reinitializeDropdowns();
        sharedUtility.reinitializeTable("table[data-app-datatables]", "div[data-app-datatables-filters]");
        sharedUtility.initializeFileupload();

        // Setup date validation
        jQuery.validator.addMethod("date",
            function(value: string, element: any) {
                if (value === "") {
                    return this.optional(element);
                }

                const momentDate = moment(value, ["DD/MM/YYYY HH:mm", "DD/MM/YYYY"], true);

                return this.optional(element) || momentDate.isValid();
            });

        RequiredFieldMarker.markRequiredInputFields();

        $("input.numeric-input").each((index, element) => {
            const $element = $(element);

            const settings: INumericInputModuleOptions = {
                decimalCount: 0,
                positiveOnly: true
            };

            if ($element.data("is-numeral") === false) {
                settings.numeral = false;
            }

            new NumericInputModule(element, settings);
        });

        // Setup number validation
        let defaultNumberValidatorMethod: (value: string, element: Element) => boolean = ($ as any).validator.methods.number;
        jQuery.validator.addMethod("number", function(value: string, element: Element) {

            defaultNumberValidatorMethod = defaultNumberValidatorMethod.bind(this); // bind this, so "this" variable is correct in defaultNumberValidatorMethod

            const numericInputModule = $(element).data("numericinput-module");

            if (numericInputModule === undefined) { // Return default number validator, if not using numeric input module
                return defaultNumberValidatorMethod(value, element);
            }

            return defaultNumberValidatorMethod(numericInputModule.getRawValue(), element);
        });

        // Notificationer beskeder
        initNotifications();

        preventDoubleSubmit();

        enableSelect2OpenOnFocus();
        new Historiklog().init();


    });
})();

const preventDoubleSubmit = () => {

    const resetTimeout = 1500;

    $(document).on("click", "button[type=submit], button.submit-button, input[type=submit]", function () {

        const $this = $(this);

        setTimeout(() => {
            $this.attr("disabled", "disabled");
        }, 1);

        setTimeout(() => {
            $this.removeAttr("disabled");
        }, resetTimeout);
    });
};

function enableSelect2OpenOnFocus() {

    // on first focus (bubbles up to document), open the menu
    $(document).on("focus", ".select2-selection.select2-selection--single", function () {
        $(this).closest(".select2-container").siblings("select:enabled").select2("open");
    });

    // steal focus during close - only capture once and stop propogation to prevent focus loop
    $("select:not(.no-select2)").on("select2:closing", (e) => {
        $(e.target).data("select2").$selection.one("focus focusin", (event) => {
            event.stopPropagation();
        });
    });
}
