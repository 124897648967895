import { Localization } from "../localization-module";

export class Historiklog {

    private updateContentDebounceHandle: number;
    private updateFiltersDebounceHandle: number;

    private $historiklog: JQuery;
    private $historiklogContent: JQuery;
    private $historiklogSpinner: JQuery;

    public init() {

        this.$historiklog = $(".historiklog-sidenav");

        if (this.$historiklog.length === 0) {
            return;
        }

        this.$historiklogContent = this.$historiklog.find(".historiklog-content");
        this.$historiklogSpinner = this.$historiklog.find(".historiklog-loader");

        $(".sidenav-trigger").hover((e) => {
            const $this = $(e.target);
            $this.addClass("visible");
        }, (e) => {
            const $this = $(e.target);
            $this.removeClass("visible");
        });

        $(".sidenav-trigger").on("click", () => {

            this.$historiklogContent.hide();
            this.$historiklogSpinner.show();
            this.$historiklog.addClass("visible");

            this.updateHistoriklogContent();
            this.updateChildEntitetFilterItems();
        });

        $(".sidenav-close").on("click", () => {
            this.$historiklog.removeClass("visible");
            $(".sidenav-trigger").removeClass("visible");
        });

        $("#childEntitetType").on("change", () => {

            this.$historiklog.find("#childEntitetId").val(null).trigger("change.select2");

            this.updateChildEntitetFilterItems();
            this.updateHistoriklogContent();
        });

        $("#childEntitetId").on("change", () => {
            this.updateHistoriklogContent();
        });
    }

    private updateChildEntitetFilterItems() {
        const values = this.getValues();

        const $childEntitetIdDropdon = this.$historiklog.find("#childEntitetId");

        if (values.childEntitetType === undefined || values.childEntitetType === null || values.childEntitetType === "") {
            const option = new Option(Localization.getText("DerSkalVaelgesEnType"), "disabled-placeholder-option", false, true);
            option.disabled = true;

            $childEntitetIdDropdon.prop("disabled", true);
            this.setChildEntitetIdFilterOptions(option);
            return;
        }

        this.updateFiltersDebounceHandle = this.debounce(this.updateFiltersDebounceHandle, () => {
            $.ajax({
                method: "GET",
                url: this.$historiklog.find(".historiklog-filter").data("get-child-entitet-items-url"),
                data: {
                    entitetType: values.entitetType,
                    entitetId: values.entitetId,
                    childEntitetType: values.childEntitetType,
                }
            }).done((selectListItems: Array<{ Text: string, Value: string; Selected: boolean; Disabled: boolean }>) => {

                const options: HTMLOptionElement[] = [];

                for (let i = 0; i < selectListItems.length; i++) {
                    const selectListItem = selectListItems[i];
                    const option = new Option(selectListItem.Text, selectListItem.Value, false, selectListItem.Selected);

                    options.push(option);
                }

                $childEntitetIdDropdon.prop("disabled", false);
                this.setChildEntitetIdFilterOptions(options);
            });
        });
    }

    private setChildEntitetIdFilterOptions(options: HTMLOptionElement[] | HTMLOptionElement) {
        const placeholderOption = new Option("", "", false, false);
        const $childEntitetIdsDropdown = this.$historiklog.find("#childEntitetId").html("");

        $childEntitetIdsDropdown.append(placeholderOption).append(options).trigger("change");
    }

    private updateHistoriklogContent() {

        const values = this.getValues();

        this.$historiklogContent.hide();
        this.$historiklogSpinner.show();

        this.updateContentDebounceHandle = this.debounce(this.updateContentDebounceHandle, () => {

            $.ajax({
                method: "GET",
                url: this.$historiklog.data("get-url"),
                data: {
                    entitetType: values.entitetType,
                    entitetId: values.entitetId,
                    childEntitetType: values.childEntitetType,
                    childEntitetId: values.childEntitetId
                }
            }).done((html) => {
                this.$historiklogSpinner.hide();
                this.$historiklogContent.html(html);
                this.$historiklogContent.show();
                this.$historiklogContent.find(".show-more").off("click").on("click", (e) => {

                    const $row = $(e.target).closest("tr.historiklog-row");
                    const $rowsToToggle = $row.nextUntil("tr.historiklog-row");

                    const isShowingMore = $row.hasClass("showing-more");

                    if (isShowingMore) {
                        $row.removeClass("showing-more");
                        $rowsToToggle.removeClass("visible");
                    } else {
                        $row.addClass("showing-more");
                        $rowsToToggle.addClass("visible");
                    }
                });
            });

        });
    }

    private debounce(debounceHandle: number, callback: () => void): number {

        if (debounceHandle !== undefined) {
            window.clearTimeout(debounceHandle);
        }

        return window.setTimeout(() => {
            callback();
        }, 200);

    }

    private getValues() {
        const entitetType = $("#entitetType").val() as string;
        const entitetId = $("#entitetId").val() as string;
        const childEntitetType = $("#childEntitetType").val() as string;
        const childEntitetId = $("#childEntitetId").val() as string;

        return {
            entitetType,
            entitetId,
            childEntitetType,
            childEntitetId
        };
    }
}