interface JQuery {
    serializeObject(): JQuery;
    isInViewport(visiblity?: number): JQuery;
}

$.fn.extend({
    serializeObject: function () {
        let results: {}, arr: JQuery.NameValuePair[];
        results = {};
        arr = this.serializeArray();
        for (let i = 0, len = arr.length; i < len; i++) {
            let obj = arr[i];
            //Check if results have a property with given name
            if (results.hasOwnProperty(obj.name)) {
                //Check if given object is an array
                if (!results[obj.name].push) {
                    results[obj.name] = [results[obj.name]];
                }
                results[obj.name].push(obj.value || '');
            } else {
                results[obj.name] = obj.value || '';
            }
        }
        return results;
    }
});

$.fn.extend({
    isInViewport: function(triggerAtPercentageVisibility?: number) {
        const y = triggerAtPercentageVisibility || 1;
        const elementTop = $(this).offset().top;
        const elementBottom = elementTop + $(this).outerHeight();
        const elementHeight = $(this).height();
        const viewportTop = $(window).scrollTop();
        const viewportBottom = viewportTop + $(window).height();
        const deltaTop = Math.min(1, (elementBottom - viewportTop) / elementHeight);
        const deltaBottom = Math.min(1, (viewportBottom - elementTop) / elementHeight);

        return deltaTop * deltaBottom >= y;
    }
});
